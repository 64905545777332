import '../styles/Header.css';
import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import logo from '../assets/logo-simple.png';
import { NavLink } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import SettingsIcon from '@mui/icons-material/Settings';

function Header() {
  return (
    <AppBar className="header" position="fixed" elevation={0}>
      <Toolbar className="toolbar">
        <div className="logo-container">
          <img src={logo} alt="logo" className="logo" />
          {/* <Typography variant="h6" className="dashboard-text">
            Dashboard
          </Typography> */}
          <NavLink to="/settings" onClick={() => window.scrollTo(0, 0)} id="settings-navlink" className={({ isActive }) => isActive ? 'navlink active' : 'navlink'}>
            <span className="navlink-text">Dashboard</span>
            <SettingsIcon className="settings-nav-icon" />
          </NavLink>
        </div>
        <div className="nav-links px-4">
          <NavLink exact to="/" onClick={() => window.scrollTo(0, 0)} className={({ isActive }) => isActive ? 'navlink active' : 'navlink'}>
            Home
          </NavLink>
          {/* <NavLink exact to="/about-us" onClick={() => window.scrollTo(0, 0)} className={({ isActive }) => isActive ? 'navlink active' : 'navlink'}>
            About Us
          </NavLink> */}
          <div className="navitem-with-dropdown">
            <NavLink to="/about-us" onClick={() => window.scrollTo(0, 0)} className={({ isActive }) => isActive ? 'navlink active' : 'navlink'}>
              About Us
            </NavLink>
            <div className="dropdown">
              <div className="dropdown-content">
                <NavLink 
                    to={`/certificates`} 
                    onClick={() => window.scrollTo(0, 0)}  
                    className="dropdown-navlink"
                >
                    Certificates
                </NavLink>
                <NavLink 
                    to={`/staff`} 
                    onClick={() => window.scrollTo(0, 0)}  
                    className="dropdown-navlink"
                >
                    Staff
                </NavLink>
              </div>
            </div>
          </div>
          <NavLink to="/projects" onClick={() => window.scrollTo(0, 0)} className={({ isActive }) => isActive ? 'navlink active' : 'navlink'}>
            Projects
          </NavLink>
          <NavLink to="/services" onClick={() => window.scrollTo(0, 0)} className={({ isActive }) => isActive ? 'navlink active' : 'navlink'}>
            Services
          </NavLink>
          <NavLink to="/products" onClick={() => window.scrollTo(0, 0)} className={({ isActive }) => isActive ? 'navlink active' : 'navlink'}>
            Products
          </NavLink>
          {/* <NavLink to="/certificates" onClick={() => window.scrollTo(0, 0)} className={({ isActive }) => isActive ? 'navlink active' : 'navlink'}>
            Certificates
          </NavLink> */}
          <NavLink to="/contact-us" onClick={() => window.scrollTo(0, 0)} className={({ isActive }) => isActive ? 'navlink active' : 'navlink'}>
            Contact Us
          </NavLink>
          {/* <NavLink to="/staff" onClick={() => window.scrollTo(0, 0)} className={({ isActive }) => isActive ? 'navlink active' : 'navlink'}>
            Staff
          </NavLink> */}
          
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
