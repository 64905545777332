import '../styles/Sidebar.css';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Drawer, IconButton, List, ListItem, ListItemText } from '@mui/material';

import Logo from '../assets/logo-simple.png';
import MenuIcon from '@mui/icons-material/Menu';

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const links = [
    { to: '/', text: 'Home' },
    { to: '/about-us', text: 'About Us' },
    { to: '/projects', text: 'Projects' },
    { to: '/services', text: 'Services' },
    { to: '/products', text: 'Products' },
    // { to: '/certificates', text: 'Certificates' },
    { to: '/contact-us', text: 'Contact Us' },
    { to: '/staff', text: 'Staff' },
    // { to: '/contact-us', text: 'Contact Us' },
  ];

  return (
    <div>
      <div className="sidebar-container">
        <img src={Logo} alt="logo" width={100} className="sidebar-logo"/>
        <List className="sidebar">
          {links.map((link, index) => (
            <NavLink 
              key={index} 
              to={link.to} 
              className={({ isActive }) => isActive ? 'sidebar-link active' : 'sidebar-link'} 
              onClick={toggleDrawer}
            >
              <ListItem button>
                <ListItemText primary={link.text} className="sidebar-link-text"/>
              </ListItem>
            </NavLink>
          ))}
        </List>
      </div>
      <IconButton onClick={toggleDrawer} className="menu-button">
        <MenuIcon />
      </IconButton>
      <Drawer anchor="left" open={isOpen} onClose={toggleDrawer}  sx={{
          "& .MuiDrawer-paper": {
            width: "75%",
            backgroundColor: "#bda13e",
            padding: "20px",
          }
        }} className="mobile-sidebar">
        <List>
        <img src={Logo} alt="logo" width={75} className="sidebar-logo-mobile"/>

          {links.map((link, index) => (
            <NavLink 
              key={index} 
              to={link.to} 
              className={({ isActive }) => isActive ? 'sidebar-link active' : 'sidebar-link'} 
              onClick={toggleDrawer}
            >
              <ListItem button>
                <ListItemText primary={link.text} />
              </ListItem>
            </NavLink>
          ))}
        </List>
      </Drawer>
    </div>
  );
};

export default Sidebar;
