import './App.css';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { lazy, Suspense } from 'react';

import Header from './components/Header';
import Sidebar from './components/Sidebar';

const Login = lazy(() => import('./components/pages/Login.jsx'));

const Home = lazy(() => import('./components/pages/Home.jsx'));
const ServicesHomePage = lazy(() => import('./components/pages/services/ServicesHomePage.jsx'));
const ServiceDetailsPage = lazy(() => import('./components/pages/services/ServiceDetailsPage.jsx'));

const ProjectsHomePage = lazy(() => import('./components/pages/projects/ProjectsHomePage.jsx'));
const CategoryDetailsPage = lazy(() => import('./components/pages/projects/CategoryDetailsPage.jsx'));
const ProjectDetailsPage = lazy(() => import('./components/pages/projects/ProjectDetailsPage.jsx'));

const ProductsHomePage = lazy(() => import('./components/pages/products/ProductsHomePage.jsx'));
const ProductDetailsPage = lazy(() => import('./components/pages/products/ProductDetailsPage.jsx'));

const CertificatesHomePage = lazy(() => import('./components/pages/certificates/CertificatesHomePage.jsx'));

const StaffHomePage = lazy(() => import('./components/pages/staff/StaffHomePage.jsx'));

const AboutUsPage = lazy(() => import('./components/pages/AboutUsPage.jsx'));
const ContactUsPage = lazy(() => import('./components/pages/ContactUsPage.jsx'));

const SettingsPage = lazy(() => import('./components/pages/SettingsPage.jsx'));

function useTokenChangeEffect() {
  const [token, setToken] = useState(localStorage.getItem('token') || '');
      useEffect(() => {
        // Save the token to localStorage when it changes
        localStorage.setItem('token', token);
        // Trigger a re-render whenever the token changes
        setToken(token);
      }, [token]);

      useEffect(() => {
        // Listen for changes to the localStorage from other browsing contexts
        const handleStorageChange = (e) => {
          if (e.key === 'token') {
            setToken(e.newValue);
          }
        };

        window.addEventListener('storage', handleStorageChange);

      }, []);

  return [token, setToken];

}

function App() {
  const [token, setToken] = useTokenChangeEffect();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  
  useEffect(() => {
    if(token !== '' && token !== null){
      setIsLoggedIn(true);
    }else{
      setIsLoggedIn(false);
    }
  }, [token]);


  return (
    <Suspense fallback={<div className="container">Loading...</div>}>
      <Router>
        {isLoggedIn  && <Header />}
        {isLoggedIn  && <Sidebar />}
        <Routes>
          <Route path="/" element={isLoggedIn ? <Home  isLoggedIn={isLoggedIn}/> : <Login setIsLoggedIn={setIsLoggedIn}/>} />
          <Route path='/login' element={<Login setIsLoggedIn={setIsLoggedIn}/>} />
          <Route path="/services" element={<ServicesHomePage />} />
          <Route path="/services/:id" element={<ServiceDetailsPage />} />
          <Route path="/projects" element={<ProjectsHomePage />} />
          <Route path="/projects/category/:id" element={<CategoryDetailsPage />} />
          <Route path="/projects/:id" element={<ProjectDetailsPage />} />
          <Route path="/products" element={<ProductsHomePage />} />
          <Route path="/products/:id" element={<ProductDetailsPage />} />
          <Route path="/certificates" element={<CertificatesHomePage />} />
          <Route path="/staff" element={<StaffHomePage />} />
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="/contact-us" element={<ContactUsPage />} />

          <Route path="/settings" element={<SettingsPage />} />

        </Routes>
      </Router>
    </Suspense>
  );
}

export default App;
